<template>
  <div class="mt-10">
    <label for="answer" v-html="this.question"></label>
    <div class="mt-3 inline-block min-w-full">
      <div class="inline-flex min-w-full">
        <input
          type="range"
          min="1"
          max="5"
          :required="this.mandatory ? true : false"
          name="answer"
          v-model="this.answer"
          id="answer"
          class="flex-1"
          @input="$emit('addAnswer', this.answer, this.id)"
        />

        <div class="ml-2">
          <span class="text-3xl" v-if="this.answer == 4">&#128512;</span>
          <span class="text-3xl" v-else-if="this.answer == 5">&#128513;</span>
          <span class="text-3xl" v-else-if="this.answer == 2">&#128542;</span>
          <span class="text-3xl" v-else-if="this.answer == 1">&#128546;</span>
          <span class="text-3xl" v-else>&#128528;</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  EmojiHappyIcon,
  EmojiSadIcon,
  QuestionMarkCircleIcon,
} from "@heroicons/vue/solid";
export default {
  name: "ScaleOneToFive",
  props: ["question", "mandatory", "id"],
  components: {
    EmojiHappyIcon,
    EmojiSadIcon,
    QuestionMarkCircleIcon,
  },
  data() {
    return { answer: 3 };
  },
  methods: {
    sendDefaultToParent() {
      this.$emit("addAnswer", this.answer, this.id);
    },
  },
  mounted() {
    this.sendDefaultToParent();
  },
};
</script>
