<template>
  <div
    v-show="this.withPicture"
    class="pt-5 sm:mt-0 sm:col-span-2 flex justify-center"
  >
    <div
      class="w-1/4 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-400 border-dashed rounded-md"
    >
      <div class="space-y-1 text-center">
        <svg
          class="mx-auto h-12 w-12 text-gray-500"
          stroke="currentColor"
          fill="none"
          viewBox="0 0 48 48"
          aria-hidden="true"
        >
          <path
            d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <div class="flex text-sm text-gray-600">
          <label
            for="file"
            class="relative cursor-pointer rounded-md font-medium text-black hover:text-gray-500 focus-within:outline-none focus-within:ring-1 focus-within:ring-offset-2 focus-within:ring-white"
          >
            <span v-show="!this.file">Upload a file</span>
            <input
              id="file"
              name="file-upload"
              type="file"
              class="sr-only"
              ref="file"
              v-on:change="handleFileUpload()"
            />
          </label>
          <span v-show="this.file" class="font-bold text-black">
            {{ this.file.name }}
          </span>
        </div>
        <p class="text-xs text-gray-600">
          PNG, JPG, GIF up to 10MB
        </p>
      </div>
    </div>
  </div>
  <div
    v-show="this.withComplement"
    class="pt-5 sm:mt-0 sm:col-span-2 flex justify-center"
  >
    <input
      type="text"
      name="complement"
      :required="this.mandatory ? true : false"
      id="complement"
      class="shadow-sm block w-full sm:text-sm text-black border-gray-300 px-4 rounded-full"
      :placeholder="this.placeholder"
      @input="$emit('addComplement', $event.target.value, this.id)"
    />
  </div>
</template>

<script>
export default {
  name: "OptionForSurveyAnswers",
  props: ["withPicture", "withComplement", "placeholder", "mandatory", "id"],
  data() {
    return {
      file: "",
    };
  },
  methods: {
    handleFileUpload() {
      this.file = this.$refs.file.files[0];
      let formData = new FormData();
      formData.append("file", this.file);
    },
  },
};
</script>
