<template>
  <footer class="encom_primary">
    <div class="py-4 px-4 overflow-hidden sm:px-6 lg:px-8 footer-text">
      <p class="text-center text-base text-white">
        Les informations recueillies au travers de nos formulaires serviront
        uniquement dans le cadre de la mise en oeuvre de votre solution par les
        services d'Encom, afin de répondre à l'un ou plusieurs des objectifs
        ci-après : <br />
        1. Préparer au mieux le déploiement de votre solution mise en oeuvre par
        Encom.<br />
        2. Préparer au mieux votre formation à la solution mise en oeuvre par
        Encom.<br />
        3. Recueillir votre niveau de satisfaction quant aux services d'Encom
        dans le but de les améliorer. Elles ne seront pas utilisées à d'autres
        fins et seront conservées dans nos bases de données pour une durée de 5
        ans. Vous pouvez exercer vos droits en écrivant à dpo@encom-conseil.fr
        <br />
        Voir notre
        <a href="https://encom-conseil.fr/donnees-personnelles/"
          ><span class="link_encom_confidentiality"
            >politique de confidentialité</span
          ></a
        >
      </p>
      <p class="mt-8 text-center text-base text-white">
        &copy; 2021 Encom, Inc. All rights reserved.
      </p>
    </div>
  </footer>
</template>

<script>
export default {
  setup() {
    return {};
  },
};
</script>

<style>

</style>
