<template>
  <div class="mt-10">
    <h1 v-html="this.question" class="mb-1"></h1>
    <textarea
      type="text"
      name="open-answer"
      :required="this.mandatory ? true : false"
      id="open-answer"
      rows="5"
      class="shadow-md block w-full sm:text-sm text-black border-gray-900 px-2"
      placeholder=""
      @input="$emit('addAnswer', $event.target.value, this.id)"
    ></textarea>
  </div>
</template>

<script>
export default {
  name: "OpenQuestion",
  props: ["question", "mandatory", "id"],
  components: {},
  data() {
    return {};
  },
};
</script>

<style>
input {
  line-height: 4rem;
}
</style>
