<template>
  <router-view></router-view>
  <!-- <Footer /> -->
</template>

<script>
import Footer from "./components/Footer.vue";
export default {
  components: {
    Footer,
  },
  setup() {},
};
</script>
<style>
.footer-text p {
  font-size: 10px !important;
  font-weight: normal !important;
}
.link_encom_confidentiality {
  font-size: 10px !important;
  font-weight: normal !important;
  text-decoration: underline;
}
.footer-text p ul {
  line-height: 110%;
}
@media only screen and (max-width: 820px) {
  .footer-text p {
    line-height: 140%;
  }
}
</style>
