<template>
  <div class="bg-white min-h-screen flex flex-col lg:relative">
    <div class="flex-grow flex flex-col">
      <main class="flex-grow flex justify-center flex-col bg-white">
        <div
          class="flex-grow mx-auto w-full flex justify-center flex-col px-4 sm:px-6 lg:px-8"
        >
          <div class="flex-shrink-0 justify-center pt-16 grid sm:grid-cols-6">
            <div class="hero container max-w-screen-lg mx-auto pb-10 flex justify-center col-start-1 col-end-4">
              <img
                class="h-32 w-auto mx-auto"
                src="../assets/encom_official_2.png"
                alt=""
              />
            </div>
            <h2
              class="mt-10 mb-5 text-xl text-center font-extrabold encom_primary_text tracking-tight text-5xl col-start-1 col-end-4"
            >
              Merci &#128591;
            </h2>
            <span class="mt-10 text-center text-2xl text-gray-500 col-start-1 col-end-4">
              {{ this.text }}
            </span>
          </div>
          <div class="flex-shrink-0 my-auto">
            <div class="mt-6">
              <a
                href="https://telecom0525-clients.bluerocktel.net/"
                class="text-base encom_primary_text encom_primary_button font-medium text-indigo-600 hover:text-indigo-500"
                >Votre espace client<span aria-hidden="true"> &rarr;</span></a
              >
            </div>
          </div>
        </div>
      </main>
    </div>
    <div class="hidden lg:block lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
      <img
        class="absolute inset-0 h-full w-full object-cover"
        src="../assets/paris_background.jpg"
        alt=""
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "SurveyCompleted",
  props: ["text"],
};
</script>

<style></style>
