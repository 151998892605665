<template>
  <div class="survey_page" v-show="this.surveyData.id && !this.surveyCompleted">
    <div class="encom_icon">
      <img
                class="encom_icon_img"
                src="../assets/encom_official.png"
                alt=""
              />
              </div>
    <div class="survey_card">
      <!-- <h2 class="font-medium text-white mt-10 text-2xl pl-2 pr-2">
        {{ this.surveyInfo.subject }}
      </h2> -->
      
      <div
        class="items-center justify-center bg-gray-50 pt-10 pb-5 px-4 sm:px-6 lg:px-8 card card-height mb-0"
      >
      
        <div class="flex flex-wrap pb-10 w-full" v-if="questionsGroupCount > 1">
          <progress-bar
            :percentage="this.getPercentage(this.surveyData.id)"
            class="mx-2 mb-2 h-5"
          >
            <span class="text-xs text-white w-full flex justify-end pr-2"
              >{{ this.getPercentage(this.surveyData.id) }}%</span
            >
          </progress-bar>
        </div>
        <h1 class="font-medium pb-0 text-2xl">
          {{ this.surveyInfo.textBefore }}
        </h1>
        <div v-if="this.surveyData.id && !this.surveyCompleted">
          <form
            @submit.prevent="
              (questionsGroupCount = 1) ||
              currentQuestionGroup >= questionsGroupCount
                ? this.postAnswers(false)
                : this.postAnswers(true)
            "
            method="POST"
          >
            <div class="text-xl font-medium">
              <ul>
                <li v-for="question in this.surveyQuestions" :key="question.id">
                  <div v-if="question.questionType === 'openQuestion'">
                    <OpenQuestion
                      :question="question.question"
                      :mandatory="question.mandatoryReply"
                      :id="question.id"
                      @add-answer="(...args) => this.addAnswer(...args)"
                    />
                  </div>
                  <div v-if="question.questionType === 'scaleOneToFive'">
                    <ScaleOneToFive
                      :question="question.question"
                      :mandatory="question.mandatoryReply"
                      :id="question.id"
                      @add-answer="(...args) => this.addAnswer(...args)"
                    />
                  </div>

                  <div v-if="question.questionType === 'scaleOneToTen'">
                    <ScaleOneToTen
                      :question="question.question"
                      :mandatory="question.mandatoryReply"
                      :id="question.id"
                      @add-answer="(...args) => this.addAnswer(...args)"
                    />
                  </div>
                  <div v-if="question.questionType === 'consent'">
                    <Consent
                      :question="question.question"
                      :mandatory="question.mandatoryReply"
                      :id="question.id"
                      :withPicture="withPicture"
                      @add-answer="(...args) => this.addAnswer(...args)"
                    />
                  </div>
                  <div v-if="question.questionType === 'checkbox'">
                    <CheckMultipleAnswers
                      :question="question.question"
                      :mandatory="question.mandatoryReply"
                      :id="question.id"
                      :name="question.id"
                      @add-answer="(...args) => this.addAnswer(...args)"
                      type="checkbox"
                    />
                  </div>
                  <div v-if="question.questionType === 'multipleChoice'">
                    <CheckMultipleAnswers
                      :question="question.question"
                      :mandatory="question.mandatoryReply"
                      :id="question.id"
                      :name="question.id"
                      @add-answer="(...args) => this.addAnswer(...args)"
                      type="radio"
                    />
                  </div>
                  <div v-if="question.questionType === 'yesOrNo'">
                    <YesOrNo
                      :question="question.question"
                      :mandatory="question.mandatoryReply"
                      :id="question.id"
                      @add-answer="(...args) => this.addAnswer(...args)"
                    />
                  </div>
                </li>
              </ul>
            </div>
            <div
              :class="[
                questionsGroupCount > 1 ? 'justify-between' : 'justify-center',
                'flex mt-5 text-center',
              ]"
            >
              <!-- <button
                v-if="questionsGroupCount > 1"
                @click.prevent="this.updateAndGetQuestions('reset')"
                class="ml-10 encom_pink_bg py-2 px-4 border border-gray-300 shadow-sm text-sm font-medium text-white w-28"
              >
                Reset
              </button> -->
              <button
                type="submit"
                class="mr-10 encom_primary encom_primary_hover py-2 px-4 border border-gray-300 shadow-sm text-sm font-medium text-white w-28"
                v-if="
                  questionsGroupCount > 1 &&
                    currentQuestionGroup <= questionsGroupCount
                "
              >
                Envoyer
              </button>
              <button
                v-if="
                  (questionsGroupCount = 1) ||
                    currentQuestionGroup >= questionsGroupCount
                "
                class="mr-10 encom_table_header py-2 px-4 border border-gray-300 shadow-sm text-sm font-medium text-white w-28"
              >
                Envoyer
              </button>
            </div>
            <div class="mt-4 footer-text">
              <p class="text-base text-white">
                Les informations recueillies au travers de nos formulaires
                serviront uniquement dans le cadre de la mise en oeuvre de votre
                solution par les services d'Encom, afin de répondre à l'un ou
                plusieurs des objectifs ci-après: <br /> 
                <ul>
                  <li>
                1. Préparer au mieux le
                déploiement de votre solution mise en oeuvre par Encom. 
                  </li>
                  <li>
                2.
                Préparer au mieux votre formation à la solution mise en oeuvre
                par Encom. 
                  </li>
                  <li>
                3. Recueillir votre niveau de satisfaction quant aux
                services d'Encom dans le but de les améliorer. 
                  </li>
                </ul>
                Elles ne seront pas utilisées à d'autres fins et seront
                conservées dans nos bases de données pour une durée de 5 ans.
                Vous pouvez exercer vos droits en écrivant à
                dpo@encom-conseil.fr Voir notre
                <a href="https://encom-conseil.fr/donnees-personnelles/" target="_blank"
                  ><span class="link_encom_confidentiality"
                    >politique de confidentialité</span
                  ></a
                >
              </p>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!-- <p class="text-white p-10">{{ this.arrayAnswers }}</p> -->
  </div>
  <SurveyCompleted
    v-if="this.surveyCompleted"
    :text="this.surveyInfo.textAfter"
  />
  <Error404 v-if="this.error.status == 404" :msg="this.errorMsg" />
</template>

<script>
import axios from "axios";

import Error404 from "../components/Error404.vue";
import OpenQuestion from "../components/OpenQuestion.vue";
import ScaleOneToFive from "../components/ScaleOneToFive.vue";

import ScaleOneToTen from "../components/ScaleOneToTen.vue";
import Consent from "../components/Consent.vue";
import CheckMultipleAnswers from "../components/CheckMultipleAnswers.vue";
import YesOrNo from "../components/YesOrNo.vue";
import ProgressBar from "../components/ProgressBar.vue";
import SurveyCompleted from "../components/SurveyCompleted.vue";

export default {
  name: "Survey",
  props: ["surveyKey"],
  components: {
    Error404,
    OpenQuestion,
    ScaleOneToFive,
    ScaleOneToTen,
    Consent,
    CheckMultipleAnswers,
    YesOrNo,
    ProgressBar,
    SurveyCompleted,
  },
  data() {
    return {
      answer: "",
      file1: "",
      file2: "",
      surveyData: {},
      error: {},
      errorMsg: "",
      surveyQuestions: [],
      arrayAnswers: [],
      progressStart: 10,
      contentProgress: 20,
      groupQuestionLastId: 4,
      dataReply: [],
      surveyInfo: {},
      surveyCompleted: false,
      questionsGroupCount: undefined,
      completedGroupsCount: undefined,
      testText: "<span style='color:red'>test</span>",
    };
  },
  methods: {
    addAnswer(...args) {
      this.arrayAnswers[args[1]] = args[0];
    },
    getPercentage(id) {
      return ((id - 1) / this.groupQuestionLastId) * 100;
    },
    handleFile1Upload() {
      this.file1 = this.$refs.file1.files[0];
    },
    handleFile2Upload() {
      this.file2 = this.$refs.file2.files[0];
    },
    async updateAndGetQuestions(operation) {
      let branchId = this.$route.query.surveyBranchId;
      if (branchId) {
        axios
          .put(
            `${process.env.VUE_APP_API}/v1/replyToSurveyRequests/${this.surveyKey}?operation=${operation}&surveyBranchId=${branchId}`
          )
          .then((response) => {
            this.getSurveyInfo(response.data.survey_id);

            this.questionsGroupCount = response.data.totalGroupsCount;
            this.completedGroupsCount = response.data.completedGroupsCount;
            if (this.completedGroupsCount < this.questionsGroupCount) {
              this.surveyData = response.data;
              this.surveyQuestions = response.data.survey_questions;
              if (operation === "reset") {
                location.reload();
              }
            } else {
              this.surveyCompleted = true;
            }
          })
          .catch((error) => {
            this.error = error.response;
            this.errorMsg = error.response.data.response;
            console.error(error.response);
          });
      } else {
        axios
          .put(
            `${process.env.VUE_APP_API}/v1/replyToSurveyRequests/${this.surveyKey}?operation=${operation}`
          )
          .then((response) => {
            this.getSurveyInfo(response.data.survey_id);
            this.questionsGroupCount = response.data.totalGroupsCount;
            this.completedGroupsCount = response.data.completedGroupsCount;
            if (this.completedGroupsCount < this.questionsGroupCount) {
              this.surveyData = response.data;
              this.surveyQuestions = response.data.survey_questions;
              if (operation === "reset") {
                location.reload();
              }
            } else {
              this.surveyCompleted = true;
            }
          })
          .catch((error) => {
            this.error = error.response;
            this.errorMsg = error.response.data.response;
            console.error(error.response);
          });
      }
    },
    getQuestions() {
      axios
        .get(
          `${process.env.VUE_APP_API}/v1/replyToSurveyRequests/${this.surveyKey}`
        )
        .then((response) => {
          this.surveyData = response.data;
          this.questionsGroupCount = response.data.totalGroupsCount;
          this.surveyQuestions = response.data.survey_questions;
        })
        .catch((error) => {
          this.error = error.response;
          this.errorMsg = error.response.data.response;
          console.error(error.response);
        });
    },
    async getSurveyInfo(id) {
      axios
        .get(`${process.env.VUE_APP_API}/v1/surveys/${id}`)
        .then((response) => {
          this.surveyInfo = response.data;
        })
        .catch((error) => {
          console.error(error.response);
        });
    },
    postAnswers(isComplete) {
      let firstValueIndex = [];
      this.dataReply = [];

      for (let index = 0; index < this.arrayAnswers.length; index++) {
        if (this.arrayAnswers[index]) {
          firstValueIndex.push(index);
        }
      }

      for (
        let index = firstValueIndex[0];
        index < this.arrayAnswers.length;
        index++
      ) {
        let data = {
          reply: this.arrayAnswers[index],
          file: "",
          complement: "",
          id: index,
        };
        this.dataReply.push(data);
      }

      const options = {
        method: "POST",
        url: `${process.env.VUE_APP_API}/v1/replyToSurveyRequests?replyToSurveyRequestKey=${this.surveyKey}`,
        headers: { "Content-Type": "application/json" },
        data: JSON.stringify(this.dataReply),
      };

      axios
        .request(options)
        .then((response) => {
          if (!isComplete) {
            location.reload();
          }
          this.surveyCompleted = isComplete;
        })
        .catch(function(error) {
          console.error(error);
        });
    },
  },
  mounted() {
    this.updateAndGetQuestions("update");
  },
};
</script>

<style>
.encom_icon{
position:absolute;
left:20px;
top:20px;
}
.encom_icon_img{
  height: 60px;
  width: auto;
}
@media only screen and (max-width: 820px) {
  .encom_icon{
position:absolute;
left:10px;
top:10px;
}
.encom_icon_img{
  height: 30px;
  width: auto;
}
}
</style>
